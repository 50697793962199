import realtime from "../../assets/real-time.png";
import diverseone from "../../assets/diverse.png";
import ai_plag from "../../assets/ai-plag.png";
import investment from "../../assets/invest.png";

export const Project_data = [
  {
    id: 1,
    title: "Medicare (HMS)",
    desc: "HMS is ready to use web application, which can be used by Hospitals to manage their tasks like- booking Appointments, creating reports, etc.",
    tech_stack:
      "React, Redux,  AntDesign, NodeJS, EpressJS, MongoDB, NodeMailer",
    img: "https://user-images.githubusercontent.com/100460788/215808752-4ebfb582-1db0-45e4-ac53-a87a5f1b75ea.png",
    category: "MERN",
    Deploy_link: "https://hms-user-side.netlify.app/",
    Github_link:
      "https://github.com/centauricoder01/Hospital_Management_System",
  },
  {
    id: 7,
    title: "Realtime Quiz competetion",
    desc: "A real-time quiz application that lets you play math quizzes with people around the world. It shows a leaderboard with the highest ratings and gives you the chance to compete with others.",
    tech_stack: "Nextjs, Socket.io, Prisma, TailwindCSS",
    img: realtime,
    category: "NEXTJS",
    Deploy_link: "https://real-time-quiz-website-s9ot.onrender.com",
    Github_link: "https://github.com/centauricoder01/real-time-quiz-website",
  },
  {
    id: 100,
    title: "AI Plagiarism Detector",
    desc: "This application is an AI-powered plagiarism detection tool that allows users to upload PDF and DOCX files. The app analyzes the uploaded documents to check for plagiarized content against a wide range of sources.",
    tech_stack: "Typescript, Nextjs, OpenAI, ShadCN",
    img: ai_plag,
    category: "AI",
    Deploy_link:
      "https://puretextai-centauricoder01-centauricoder01s-projects.vercel.app/",
    Github_link:
      "https://github.com/centauricoder01/rajendra-patel-AIPlagiarismDetector",
  },

  {
    id: 8,
    title: "Investment Better",
    desc: "A site that helps people choose better investment options, get good recommendations on various bank card options, loan options, and also helps them choose insurance plans according to their needs.",
    tech_stack: "NextJS, ShadCN, NodeJS, EpressJS, MongoDB",
    img: investment,
    category: "NEXTJS",
    Deploy_link: "https://www.investmentbetter.com",
    Github_link: "https://github.com/centauricoder01/Hytomobi-insurance-better",
  },
  {
    id: 2,
    title: "Diverse (Social media)",
    desc: "It's a Social Media Application That Help you chats with Your Friends, and share post that are visible to your Friends. You can Follow some and also make comment on someone post..",
    tech_stack: "React, Redux, AntDesign, NodeJS, EpressJS, MongoDB",
    img: diverseone,
    category: "MERN",
    Deploy_link: "https://diversely.netlify.app",
    Github_link: "https://github.com/centauricoder01/Social-media-application",
  },
  {
    id: 3,
    title: "Task Planner",
    desc: "Task planner is basically a website, where you can plan your task, assign the task to the other person who belongs to your organization and also updated it, based on the status.",
    tech_stack: "React, Redux, AntDesign, NodeJS, EpressJS, MongoDB",
    img: "https://user-images.githubusercontent.com/103047446/227739623-41bfa193-750b-406f-869b-c833707c850a.png",
    category: "MERN",
    Deploy_link: "https://task-planner-tan.vercel.app/",
    Github_link: "https://github.com/centauricoder01/Paypal-Assignment",
  },
];
